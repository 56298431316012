@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  list-style: none !important;
  text-decoration: none !important;
  font-family: "Manrope", sans-serif;
  font-optical-sizing: auto;
}
:root {
  --p: #222;
  --s: #000;
  --t: #ffd809;
}
ul {
  margin: 0 !important;
  padding: 0 !important;
}
.popp {
  font-family: "Poppins", sans-serif !important;
}
.caveat {
  font-family: "Caveat", cursive;
  font-weight: 500;
}

@media screen and (min-width: 993px) {
  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: linear-gradient(#333, #000);
  }
}
